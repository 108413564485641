/* General CSS */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F5F1;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

 /* Set up for implementation later to keep hexcodes and colors in place. */
.navbar--collection {
  padding: 5px;
  background-color: #5B4037;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 120px;
  margin-top: 0px;
}
 
.navbar--collaspe {
  flex-grow: 0;
  padding: 5px;
  background-color: #5B4037;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
 
.navbar--links {
  display: flex;
  flex-grow:1;
  padding: 5px;
  background-color: #5B4037;
  flex-direction: row;
  align-content: space-around;
}

.nav-link:hover {
  display: flex;
  flex-grow:1;
  padding: 5px;
  background-color: #3c1704;
  flex-direction: row;
  align-content: space-around;
}

.navbar--logo:hover {
  background-color: #3c1704;
}

.navbar--logo > a:hover {
  background-color: #3c1704;
}

.navbar--logout {
  background-color: #ECA200;
  border: 5px solid #eca200;
  border-radius: 5px;
  color:black;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  font-kerning: auto;
  align-content: flex-end;
  flex-grow: 0;
}

.selectAndSearchBarLinedUp, .selectAndSearchBarLinedUp:focus {
  align-self: center;
}

.user--favorite--beer {
  z-index: 1;
  position: absolute;
  max-height: 6vh;
  max-width: 10vw;
  top: 0vh;
  left: 0;
}

.home--map {
  margin-left:5vw;
  margin-right:5vw;
  padding-bottom:15vh;
}