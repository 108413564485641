/* Beer */

.selected--beer {
  display: flex;
  justify-content: center;
  margin: 15px;
  gap: 20px;
}

.selected--name--beer {
  background-color: #FFD371;
  font-size: x-large;
  padding-bottom: 5px;
}

.selected--desc--beer {
  font-size: larger;
  font-style: italic;
}

h5 {
  text-decoration: underline;
}

.selected--image--beer {
  position: relative;
  background-color: white;
  max-width: 25vw;
  min-width: 300px;
  min-height: auto;
  padding-bottom: 10px;
}

.selected--info {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;
  text-align: center;
  border: 1px solid white;
  max-width: 40vw;
  border: 1px solid #F7F5F1;
}

.selected--reviewTable {
  display: flex;
  flex-direction: column;
  flex-grow: 33;
  border: 1px solid #F7F5F1;
  align-items: center;
  position: relative;
  margin-top: 25px;
}

.selected--review {
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 5px;
}

.selected--image--beer:hover {
  position: relative;
  background-color: white;
  max-width: 25vw;
  margin: 10px;
  margin-left: 10px;
  margin-right: 20px;
  background-position: 100% 100%;
  transform: scale(1.05,1.01);
  box-shadow: 10px 10px 5px #FFD371;
  border-style: solid;
  border-color: white;
  border-radius: 3px;
  border-width: 1px;
  padding-bottom: 10px;
}

.beer--card--set > img {
  display: inline-block;
}

.beer--card--set {
  gap:10px;
  position: relative;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  border-style: solid;
  border-radius: 7px;
  border-width: 2px;
  min-width: 10vw;
  max-width: 16vw;
 }

.beer--list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px;
  gap: 50px;
}

.beer--card--set:nth-child(odd) .beer--card {
  background-color: #FFBD6D;
}

.beer--card--set:nth-child(even) .beer--card {
  background-color: #FFD371;
}

.beer--card {
  aspect-ratio: 1/1.6;
  cursor: pointer;
  position: relative;
  min-width: 15vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.beer--card:hover {
  background-position: 100% 100%;
  transform: scale(1.08,1.03);
  box-shadow: 10px 10px 5px black;
  border-style: solid;
  border-color: black;
  border-radius: 7px;
  border-width: 1px;
}

.beer--card--name {
  flex-shrink:1;
  align-self: flex-end;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
}

.beer--card--desc {
  font-size: .9rem;
  text-align: center;
 }

.container {
  display: block;
}

.card-img-top {
  flex-grow: 1;
  align-self: flex-end;
  margin-top: 10px;
}

.card-body {
  padding: 0;
}

.card {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.found--brews {
  height: 100%;
}

.selected--abvType {
  margin: 5px;
}

.user--favorite--brewery {
  position: fixed;
}

.breweries--found {
  position: relative;
  text-align: center;
  align-self: center;
  bottom: -10px;
  margin: 10px;
  padding: 30px;
}

.user--favorite--beer {
  z-index: 1;
  position: relative;
  max-height: 9vh;
  max-width: 8vw;
  left: 7%;
  top: 6%;
}

.user--favorite--beers {
  z-index: 1;
  position: absolute;
  max-height: 9vh;
  max-width: 8vw;
}

.found-brews {
  margin-top: 10px;
}

.brew--image {
  align-self: center;
}