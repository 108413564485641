/* Home Slider */

.slick-track { 	
    display: flex !important; 
}

.slick-slide { 	
    height: inherit !important; 	
    display: flex !important; 	
    justify-content: center; 	
    align-items: center; 
    overflow: hidden;
}

.home--slider {
    width: 60%;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 20px;
    margin-left: 20px;
}