/* Brewery */

.brew--navlinks {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  margin-left: 60px;
  margin-right: 60px;
  justify-items: center;
  align-items: center;
}

.brew--buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.brew--image {
  aspect-ratio: 1;
  max-width: 250px;
  max-height: 250px; 
}

.brew--results {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.brew--navlinks {
  list-style-type: none;
  color: darkblue
}

.brew--link:hover {
  font-size: 1.5rem;
  text-emphasis-style: initial;
}

.brew--link--active {
  font-size: 2rem;
  text-decoration: underline;
  color: blueviolet
}

.brew--navButton {
  background-color: #ECA200;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  border-radius: 10px;
  padding: 10px;
  max-width: 100vw;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 20px;
  flex-grow: 0;
}

.brew--navButton:hover {
    background-position: 100% 100%;
    transform: scale(1.08,1.03);
    box-shadow: 3px 3px 1px black;
    border-style: solid;
    border-color: black;
    border-radius: 7px;
    border-width: 2px;
}

.brew--list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin: 15px;
}

.brew--card {
  min-height: 300px;
  min-width: 300px;
  border: 2px solid black;
  border-radius: 7px;
  align-items: center;
}

.brew--card--daily {
  min-height: 300px;
  min-width: 300px;
  border: 2px solid black;
  border-radius: 7px;
  align-items: center;
  background-color: #FFBD6D;
}

.brew--card--set {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-style: solid;
  border-radius: 7px;
  border-width: 2px;
  margin-left: 15px;
  min-height: 400px;
  min-width: 400px;
  text-align: center;
}

.brew--card--set:hover {
  background-position: 100% 100%;
  transform: scale(1.08,1.03);
  box-shadow: 10px 10px 5px black;
  border-style: solid;
  border-color: black;
  border-radius: 7px;
  border-width: 1px;
}

.brew--card--daily:hover {
  background-position: 100% 100%;
  transform: scale(1.08,1.03);
  box-shadow: 10px 10px 5px black;
  border-style: solid;
  border-color: black;
  border-radius: 7px;
  border-width: 1px;

}

.brew--card--name {
  font-size: 1rem;
  font-weight: 800;
  text-decoration: underline;
}

.brew--card--home {
  border:1px solid #F7F5F1;
  background-color: #F7F5F1;
}

.selected--name--home {
  margin-top: 10px;
  font-size: larger;
  font-weight: bold;
  text-decoration: underline;
  background-color: #FFD371;
}

.selected--desc--home {
  padding: 2px;
  inline-size: auto;
  overflow-wrap: break-word;
  hyphens: auto;
}

.thumbs--up--divider:nth-child(even) .brew--card {
  background: #FFBD6D;
  background-color: #FFBD6D;
}

.thumbs--up--divider:nth-child(odd) .brew--card {
  background: #FFD371;
  background-color: #FFD371;
}

.container a:hover {
    background-color:  #F7F5F1;
}
.selected--image {
  margin-left: 0;
  margin-right: 0;
  border:1px solid #F7F5F1;
}

.brew--image--main {
  aspect-ratio: 1;
}

.selected--image:hover {
  background-color:  white;
  background-position: 100% 100%;
  transform: scale(1.05,1.05);
  box-shadow: 10px 10px 5px #FFD371;
  border-style: solid;
  border-color: white;
  border-width: 1px;
}

.selected--image--brew {
  width: 30vw;
  height: 30vw;
  border: 1px solid #F7F5F1;
}

.selected--image--brew:hover {
  background-color:  white;
  background-position: 100% 100%;
  transform: scale(1.05,1.05);
  box-shadow: 10px 10px 5px #FFD371;
  border-style: solid;
  border-color: white;
  border-width: 1px;
}

.selected--brewery {
  display: flex;
  flex-direction: row;
  margin: 15px;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.brewery--info {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  text-align: center;
  border: 1px solid white;
  width: 35vw;
  border: 1px solid #F7F5F1;
  margin-left: 10px;
  margin-right: 10px;
  gap: 5px;
}

.selected--name--brew {
  font-size: x-large;
  text-decoration: underline;
  text-align: center;
  background-color: #FFBD6D;
}

.selected--nameDesc--brew {
  margin: 5px;
  padding: 2px;
}

.selected--desc--brew {
  margin: 5px;
}

.brewery--reviews {
  display: flex;
  flex-direction: column;
  flex-grow: 33;
  border: 1px solid #F7F5F1;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.selected--review--brew {
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
}

.review--button {
  position: relative;
  left: 35%;
}

.thumbs--up--divider {
  position: relative;
}

.container {
  display: inline-block;
}

.user--favorite--brewery {
  z-index: 1;
  position: relative;
  max-height: 9vh;
  max-width: 8vw;
  left: 8%;
  top: -11vw;
}

.user--favorite--breweries {
  z-index: 1;
  position: absolute;
  max-height: 9vh;
  max-width: 8vw;
  left: 2.5vw;
  top: 1vw;
}

.beer--list--brews {
  position: relative;
  text-align: center;
  align-self: center;
  bottom: -10px;
  margin: 10px;
  padding: 30px;
}

.map--unset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.map--set {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}