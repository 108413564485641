 /* Login */

 /* Smaller Mobile */
@media screen and (min-width: 100px) and (max-width:319px) {
   section:not(.login--page--boxes) { display: none; }
   .combine {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
         " . . . ."
         " . . . ."
         " . login--page--boxes login--page--boxes ."
         " . login--page--boxes login--page--boxes ."
         " . login--page--boxes login--page--boxes ."
         " . login--page--boxes login--page--boxes ."
         " . . . ."
    }
 }

/* Mobile */
@media screen and (min-width: 320px) and (max-width:479px) {
   section:not(.login--page--boxes) { display: none; }
   .combine {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
         " . . . ."
         " . . . ."
         " . login--page--boxes login--page--boxes ."
         " . login--page--boxes login--page--boxes ."
         " . login--page--boxes login--page--boxes ."
         " . login--page--boxes login--page--boxes ."
         " . . . ."
   
    }
 }

/*Tablet*/
 @media screen and (min-width: 480px) and (max-width:768px) {
   section:not(.login--page--boxes) { display: none; }
   .combine {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
         " . . . ."
         " . login--page--boxes login--page--boxes ."
         " . login--page--boxes login--page--boxes ."
         " . login--page--boxes login--page--boxes ."
         " . login--page--boxes login--page--boxes ."
         " . . . ."
   
    }
 }

  /*Desktop*/
  @media screen and (min-width: 768px){
   .combine {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
         ". . . . . . ."
         ". . . . login--page--boxes login--page--boxes ."
         ". spline spline . login--page--boxes login--page--boxes ."
         ". spline spline . login--page--boxes login--page--boxes ."
         ". spline spline . login--page--boxes login--page--boxes ."
         ". . . . login--page--boxes login--page--boxes ."
         ". . . . . . . "
    }
 }

 .spline {
   margin-left: 100px;
   margin-top: 100px;
   padding-bottom: 100px;
   height: auto;
   width: auto;
   grid-area: spline
 }


 
 .login--page--main {
    margin: 0px;
    padding: 0px;
    background-color: #E1B4A7;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 2;
    
}

.login--page--boxes {
   position: relative;
   height: auto;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-evenly;
   max-width: 500px;
   width: 550px;
   margin-top: 100px;
   margin-left: 100px;
   padding-bottom: 100px;
   box-sizing: border-box;
   border:2px solid white;
   grid-area: login--page--boxes;
}

.login--inputs {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 5px;
   padding-left: 10px;
   margin-bottom: 100px;
}

.login--logo {
   height: auto;
   max-width: 60%;
   padding: 5px;
   padding-right: 10px;
}

.form-control {
   margin-top: 5px;
   margin-bottom: 5px;
   background-color: #e8e8e8;
}

.submit--button {
   background-color:#5B4037;
   border: 5px solid #5B4037;
   margin: 5px;
   color: #e8e8e8;
   font-style: oblique;
}

h1 {
   color: #5B4037;
   font-weight: bold;
}

a {
   color: #ECA200;
}