/* Brewery Slider */

.slick-track { 	
    display: flex !important; 
}

.slick-slide { 	
    height: inherit !important; 	
    display: flex !important; 	
    justify-content: center; 	
    align-items: center; 
}

.brewery-slider {
    margin-left: 200px;
    margin-right: 200px;
}