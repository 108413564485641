/* Main */

footer {
    position: fixed;
    bottom: 0;
    height: 3vw;
    width: 100%;
    background-color: #5B4037;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    z-index: 5;
    justify-content: space-between;
}

footer > p {
    align-self:baseline;
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: 20px;
    font-size: 1rem;
    flex-grow: 1;
}

footer > h5 {
    margin-left: 10px;
}

footer > h5, footer > p {
    text-align: left;
    color: #F7F5F1;
}

.footer--spaced {
    margin-top: 5vh;
}

.footer--brand--links {
    gap: 5px;
}