/* Home */

.home--dailyFeatures {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    margin: 20px;
    margin-top: 20px;
    text-align: center;
}

.home--featuredBrewery {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 10px;
    background-color: #F7F5F1;
    gap: 10px;
}

.home--featuredBeer {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   margin: 10px;
   background-color: #F7F5F1;
   gap: 10px;
}

.selected--image {
    margin-left: 20px;
}

.featuredBeer {
    margin-bottom: 20px;
}

h2 {
    max-height: 115px;
}