/* Ownership Table */

.owners--table {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.ownership--table {
    border: 1px solid white;
    table-layout: fixed;
    columns: 17;
    width: 100%;
    max-width: 90vw;
    align-content: center;
}

.ownership--table--head {
    border: 1px solid white;
    background-color: #ECA200;
    color: #F7F5F1;
    text-align: center;
    white-space: no wrap;
}

.ownership--table--body {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
}

.ownership--table--body tr {
    border: 1px solid white;
    text-justify: auto;
    text-align: center;
}

.ownership--table--body td{
    border: 1px solid white;
    padding: 5px;
    width: calc(100%/17);
}

.ownership--table--head th {
    width: calc(100%/17);
    margin: 2px;
    padding: 5px;
    border: 1px solid white;
}

.brewery--buttons > td{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: calc(100%/3);
}

.breweries--owned {
    height: fit-content;
    padding: 10px;
}

/* CSS for beers owned. */
.owners--beers--table{
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.breweryProduct--table {
    border: 1px solid white;
    table-layout: fixed;
    columns: 9;
    width: 100%;
    max-width:90vw;
    align-content: center;
}

.breweryProduct--table--head{
    border: 1px solid white;
    background-color: #ECA200;
    color: #F7F5F1;
    text-align: center;
    align-items: center;
    width: auto;
}

.breweryProduct--table--body {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    width: auto;
}

.breweryProduct--table--body tr {
    border: 1px solid white;
    text-justify: auto;
    text-align: center;
}

.breweryProduct--table--body td {
    border: 1px solid white;
    padding: 5px;
    width: calc(100%/9);
    overflow: hidden;
    inline-size: 120px;
    overflow-wrap: break-word;
    hyphens: auto;
}

.breweryProduct--table--head th {
    width: calc(100%/9);
    margin: 2px;
    padding: 5px;
    border: 1px solid white;
    inline-size: 130px;
}

.breweryProduct--table--body td:nth-child(4) {
    padding: 5px;
}

.breweryProduct--table--head th:nth-child(4) {
    width: calc(100%/9);
    margin: 2px;
    padding: 5px;
}

.beer--buttons > td {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: calc(100%/2);
}

.beer--buttons {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}
.owned--beers {
    height: fit-content;
    padding: 10px;
}