/* Search Bar */

.selectAndSearchBarLinedUp {
    display: flex;
    margin-left:120px;
}

.search {
    align-self: flex-start;
    z-index: 1;
}

.selectOptions {
    margin-top: 20px;
    height: 50px;
    text-align: center;
    background-color: #e8e8e8;
}

.searchInputs {
    margin-top: 20px;
    display: flex;
}

.search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 50px;
    width: 300px;
}

.searchIcon, .searchIcon:focus {
    height: 50px;
    width: 80px;
    background-color: white;
    display: grid;
    place-items: center;
}

input:focus {
    outline: none;
}

.searchIcon svg {
    font-size: 35px;
}

.dataResult {
    margin-left: 210px;
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
    display: none;
}

.dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
}

.dataItem p {
    margin-left: 10px;
}

a {
    text-decoration: none;
}

a:hover {
    background-color: lightgrey;
}

#clearBtn {
    cursor: pointer;
}